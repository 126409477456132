import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import * as actions from './actions';
import reducers from './reducers';
import ResultTable from './ResultTable'

export interface Options {
    not_destroy_on_unmount?:boolean
}

interface IProps{
    queryOption?:any;
    list: any;
    requery:(page:number,queryOption:any)=>void;
    destroyList:()=>void;
    noLoading?:boolean;
    // resultProps:{ columns : any[] };
    resultProps: any ;
    noPagination?:boolean;
}
interface IState{

}

const withQueryResultView = (queryFn:(params: any,page:number) => Promise<any>, listName:string='list',options :Options ={}) => (ResultView) => {
    class QueryResult extends Component<IProps,IState> {

        loading = options && options['noLoading'] ? false:true

        componentWillMount() {
            const { queryOption } = this.props;
            if (this.props.list == null) {
                this.props.requery(0, queryOption);
            }
        }

        componentWillReceiveProps(props) {
            const newQueryOption = props.queryOption;
            const oldQueryOption = this.props.queryOption;
            if ( ! Immutable.is(newQueryOption, oldQueryOption) ) {
                this.props.requery(0, newQueryOption)
            }
        }

        componentWillUnmount() {
            if(! (options && options['not_destroy_on_unmount'])){
                this.props.destroyList();
            }
        }

        render() {
            const { list,noPagination,noLoading = false} = this.props;
            let pagination = {};
            let result = [];
            if (list) {
                if(noPagination){
                    pagination = false;
                }else{
                    pagination = { current: list.get('number') + 1,
                        total: list.get('totalElements'),
                        defaultPageSize: 10,
                        pageSize: 10,
                        onChange: (page) => this.props.requery(page-1, this.props.queryOption),
                        showQuickJumper: true
                    };

                }
                result = list.get('content')&&list.get('content').toJS();
                this.loading = false;
            }
            return(
                <ResultView result={ result } {...this.props}
                  pagination={ pagination } loading={this.loading}
                  resultProps={ this.props.resultProps }
                />
            );
        }
    }

    const mapDispatch = (dispatch) => {
        return {
            requery: (page=0, queryOption) => { dispatch(actions.requery(listName, page, queryFn, queryOption ))},

            destroyList: () => { dispatch(actions.destroy(listName))}
        };
    }
    return connect((state: { queryResult: Immutable.Map<string, any>}) => {
        const { queryResult } = state;
         return { list: queryResult.get(listName) }
    }, mapDispatch
    )(QueryResult);
}

export default { reducers,
    withQueryResultView,
    ResultTable,
    actions
};
