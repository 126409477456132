/**
 * Created by Lily on 2019/7/3.
 */

export const ROLE_CODE = {
    channelAdmin : 'CHANNEL_ADMIN',  //渠道管理员
    orgAdmin : 'ORG_ADMIN'   //销售组织管理员
}

const config = {
    development: {
        goodArticleLink: 'http://local.datiannf.com:3000/console',
        bbtLink:         'http://bbt.com',
        domain:         '.datiannf.com'
    },

    test: {
        goodArticleLink: 'http://ga.datiannf.com/console',
        bbtLink:         'http://pushman.datiannf.com/console',
        domain:         '.datiannf.com'
    },

    production: {
        goodArticleLink: 'http://ga.visio8.com/console',
        bbtLink:         'http://pushman.visio8.com/console',
        domain:         '.visio8.com'
    }
}

export function getMenuConfig(mode) {
    return [
        {key: '/home', name: '首页', link: '/home'},
        {key: '/goodArticle', name: '好文章', isExternal: true, link: config[mode].goodArticleLink},
        {key: '/bbt', name: '邦保推', isExternal: true, link: config[mode].bbtLink},
        {
            key: '/channel', name: '渠道管理', link: '/channel'
            // subMenu:[
            // {key:'/commission',name:'佣金',link:'/channel/commission'},
            // {key:'/sale',name:'销售',link:'/channel/sale'},
            // {key:'/product',name:'产品',link:'/channel/product'},
            // ]
        },
    ]
}
export function getChannelMenuConfig() {
    return [
        {key: '/commission', name: '佣金', link: '/channel/commission',authority: ['CHANNEL_ADMIN', 'ORG_ADMIN']},
        {key: '/sale', name: '销售', link: '/channel/sale',authority: ['ORG_ADMIN']},
        {key: '/product', name: '产品', link: '/channel/product',authority: ['CHANNEL_ADMIN']}
    ]
}

export function getConfig(mode) {
    return config[mode];
}



