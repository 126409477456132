/**
 * Created by Lily on 2019/7/3.
 */

import * as React from 'react';
import {connect} from 'react-redux';
import { ROLE_CODE, getConfig } from './../../constants';
import {yuan,Pie, Line, Bar} from '../../components/Charts';
import {Row, Col} from 'antd';
import './index.less';
import numeral from 'numeral';
import { fetchReportsByMonth,fetchReportsByOrder } from './service';

export interface IProps {
    title?: React.ReactNode;
    partyId?:string;
    role?:string;
    loading?:boolean;
}
const pieData = [
    {
        item: "KAWAI健之元",
        value: 0.24
    },
    {
        item: "KAWAI命之元",
        value: 0.3
    },
    {
        item: "糖尿病保险",
        value: 0.46
    }
];
const barData = [
    {
        item: "直医",
        value: 1200
    },
    {
        item: "微盟",
        value: 1100
    },
    {
        item: "糖衣",
        value: 1000
    },
    {
        item: "金蒜师",
        value: 1000
    }
];
const lineData = {
    currentValue: 22200,
    list: [
        {
            item: "1",
            value: 1322
        },
        {
            item: "2",
            value: 1266
        },
        {
            item: "3",
            value: 2399
        }
    ]
};
const rankingListData = [
    {
        name:"人人都需要提高免疫力？",
        value: 26
    },{
        name:"人人都需要提高免疫力？",
        value: 25
    },{
        name:"人人都需要提高免疫力？",
        value: 24
    },{
        name:"人人都需要提高免疫力？",
        value: 23
    },{
        name:"人人都需要提高免疫力？",
        value: 14
    },{
        name:"人人都需要提高免疫力？",
        value: 13
    },
]
const colResponsiveProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    style: {marginBottom: 24},
};
class Home extends React.Component<IProps, any> {
    state = {
        reportByMonth:{
            list:[],
            currentValue:0
        },
        reportByOrder:[]
    };

    componentDidMount(){
        const { loading } = this.props;
        if( !loading ){
            this.queryByMonth()
            this.queryByOrder()
        }
    }
    queryByOrder(){
        const { partyId,role } = this.props;
        fetchReportsByOrder(partyId,role).then(res=>{
            let list = res.data.content.map(i=>({
                item:i.name,
                value: i.sum
            }))
            this.setState({ reportByOrder:list })
        })

    }
    queryByMonth(){
        fetchReportsByMonth().then(res=>{
            let list = res.data.map( r =>({
                    value : r.sum,
                    item : `${r.year}-${r.month}`
                })
            )
            let currentValue = res.data && res.data.length>0 ? res.data[0].sum : 0;
            this.setState({ reportByMonth : {
                list,currentValue
            } })
        })
    }
    render() {
        const { reportByMonth,reportByOrder } = this.state;
        return (
            <div className="home">
                <Row gutter={48}>
                    <Col {...colResponsiveProps}>
                        <div style={{ minHeight: 280}}>
                            <h4 style={{ marginTop: 8, marginBottom: 32 }}>
                                本月佣金 <span style={{marginLeft:10}}>{yuan(reportByMonth.currentValue)}</span>
                            </h4>
                            <Line data={reportByMonth.list}
                                  height={248}
                                  labelFormat={{ formatter: val => `${val}月`  }}
                                  tooltipFormat={(item, value) => {
                                        return {
                                            title: `${item}月`,
                                            name: `佣金`,
                                            value: yuan(value)
                                        };
                                   }}
                            />
                        </div>
                    </Col>
                    <Col {...colResponsiveProps}>
                        <div style={{ minHeight: 280}}>
                            <h4 style={{ marginTop: 8, marginBottom: 32 }}>
                                收入排行
                            </h4>
                            <Bar data={reportByOrder}
                                 height={248}
                                 transpose={true}
                                 label={true}
                                 tooltipFormat={(item, value) => ({
                                        title: item,
                                        name: `收入`,
                                        value: yuan(value)
                                        })}
                            />
                        </div>
                    </Col>
                    <Col {...colResponsiveProps}>
                        <div style={{ minHeight: 280}}>
                            <h4 style={{ marginTop: 8, marginBottom: 32 }}>
                                产品贡献率
                            </h4>
                            <Pie
                                hasLegend={ false }
                                data={pieData}
                                height={248}
                            />
                        </div>
                    </Col>
                    <Col {...colResponsiveProps}>
                        <div style={{ minHeight: 280}}>
                            <h4 style={{ marginTop: 8, marginBottom: 32 }}>
                                软文点击排行
                            </h4>
                            <ul className='rankingList'>
                                {rankingListData.map((item, i) => (
                                    <li key={i}>
                        <span
                            className={`rankingItemNumber ${i < 3 ? 'active' : ''}`}
                        >
                          {i + 1}
                        </span>
                                        <span className='rankingItemTitle' title={item.name}>
                          {item.name}
                        </span>
                                        <span className='rankingItemValue'>
                          {numeral(item.value).format('0,0')}
                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => {
    const { user } = state;
    let loading = ! (user && user.user && user.user.roles);
    if(loading){
        return { loading }
    }else{
        let isChannelAdmin = user.user.roles.includes(ROLE_CODE.channelAdmin);
        return {
            role:isChannelAdmin?'CHANNEL':'SALES_ORG',
            partyId: isChannelAdmin? user.user.channelId:user.user.orgId
        }
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return {};
};
export default connect(
    mapStateToProps,
    mapDispatchToProps)(Home)