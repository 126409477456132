import { type } from 'os'

/**
 * Created by Lily on 2019/7/3.
 */
export interface User {
  id: string
  loginName: string
  roles?: string[]
  orgId?: string
  orgName?: string
  channelId?: string
  channelName?: string
}

export enum AuthStatus {
  UNKNOWN,
  SUCCEED,
  FAILED
}

// 定义 State 结构类型
export interface UserState {
  authenticated: AuthStatus
  accessToken?: string
  refreshToken?: string
  user?: User
}

// 登录方式
export type LoginWay = 'account' | 'mobilePhone'

// 用户角色
export type PartyRole =
  | 'ROLE_CHANNEL'
  | 'ROLE_SALES_ORG'
  | 'ROLE_SALES_PERSON'
  | 'ROLE_CUSTOMER'
  | 'ROLE_SYS_OPERATOR' //
  | 'ROLE_SYS_ADMIN' //
  | 'ROLE_STORE_ADMIN' //

export type SomeRolesToRedirect = {
  [propName in PartyRole]?: string
}
