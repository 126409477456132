import React, {Component, Fragment} from 'react';
import { fetchQueryOrganization } from '../service';
import { ROLE_CODE, getConfig } from './../../constants';
import { connect } from 'react-redux';
import { Modal, Form, DatePicker, Table, Button } from 'antd';
import {FormComponentProps} from 'antd/es/form';
import { TableRowSelection } from 'antd/es/table';
import moment from 'moment';

const {  RangePicker } = DatePicker;

interface IProps extends FormComponentProps  {
    visible:boolean;
    onCancel : ()=>void;
    channelId:string;
    orgId:string;
    isChannelAdmin : boolean;
}

interface IState { }

interface ITableProps {
    channelId:string;
    onChange? : (value:any)=>void;
}
interface ITableState{
    data:any[];
    pagination:any;
    loading:boolean;
}


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
const columns = [
    {
        title: '组织名称',
        dataIndex: 'name'
    },
];

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().subtract(1,'day');
}

class SelectOrgTable extends React.Component<ITableProps,ITableState> {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return nextProps.value;
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            pagination: {},
            loading:true
        };
    }
    componentDidMount(){
        this.queryOrg()
    }
    queryOrg( page = 0 ){
        this.setState({ loading: true });
        const { channelId } = this.props;
        fetchQueryOrganization(
            { channelId:channelId },
            page
        ).then(res=>{
            const pagination = { ...this.state.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = res.data.totalElements;
            pagination.pageSize = 10;
            this.setState({
                loading: false,
                data: res.data.content,
                pagination,
            });
        })
    }
    handleTableChange = ( pagination )=>{
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager,
        });
        this.queryOrg(pagination.current-1);
    }

    onSelectChange = (selectedRowKeys)=>{
        this.triggerChange(selectedRowKeys[0]);
    }
    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(changedValue);
        }
    };

    render() {
        const { data,loading, pagination } = this.state;
        const rowSelection :TableRowSelection<any> = {
            type:'radio',
            onChange: this.onSelectChange
        };
        return(
            <Table loading={loading}
                   dataSource={data}
                   rowKey={record => record.id}
                   pagination={ pagination }
                   onChange={this.handleTableChange}
                   rowSelection = { rowSelection }
                   columns= { columns }
                   size = 'small'
            />
        )
    }
}

class ExportModal extends Component<IProps,IState>{
    state: IState = {
    };

    handleSubmit = (e) =>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { isChannelAdmin, orgId } = this.props;
                let start = values['range-picker'][0].format('YYYY-MM-DD');
                let end = values['range-picker'][1].format('YYYY-MM-DD');
                let org = isChannelAdmin?values['org']:orgId;
                let domain = getConfig(process.env.REACT_APP_MODE).domain;
                let url = `http://bbyportal${domain}/api/salesorg/commissions/reports/settlements/${org}?start=${start}&end=${end}`;
                window.open(url);
            }
        });
    }

    render(){
        const { visible,onCancel,channelId,form , isChannelAdmin } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title="导出"
                visible={ visible }
                onCancel = { onCancel }
                footer = {null}
            >
                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                    <Form.Item label="选择日期" >
                        {getFieldDecorator('range-picker', {
                                rules: [{ type: 'array', required: true, message: '请选择日期!' }]
                            })(
                                <RangePicker disabledDate={disabledDate}/>
                        )}
                    </Form.Item>
                    { isChannelAdmin &&
                        <Form.Item label="选择组织" >
                            {getFieldDecorator('org', {
                                rules: [{ required: true, message: '请选择组织!' }]
                            })(
                                <SelectOrgTable channelId = {channelId}/>
                            )}
                        </Form.Item>
                    }

                    <Form.Item wrapperCol={{
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 6 },
          }}
                    >
                        <Button onClick={ onCancel } style={{marginRight:10}}>
                            取消
                        </Button>
                        <Button type="primary" htmlType="submit">
                            确认导出
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    const { user } = state;
    return {
        channelId: user.user.channelId,
        orgId : user.user.orgId,
        isChannelAdmin : user.user.roles.includes(ROLE_CODE.channelAdmin)
    }
}

const ExportForm = Form.create<IProps>()(ExportModal);


export default connect(mapStateToProps,null)(ExportForm);
