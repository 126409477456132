import { AnyAction, Action } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  ACTION_NAME,
  AUTHENTICATED,
  SAVE_USER,
  CLEAR_USER,
  AUTHENTICATE_FAILED
} from './constant'
import {
  fetchAccountLogin,
  fetchLoadCurrentUser,
  fetchLogout,
  loginByVerifyCode
} from './service'
import { User, LoginWay, PartyRole, SomeRolesToRedirect } from './type'
import { message } from 'antd'
import Cookies from 'js-cookie'
import { getConfig, getMenuConfig } from './../constants'

/**
 * 部分角色进行跳转
 */
export const someRolesToRedirect: SomeRolesToRedirect = {
  ROLE_SYS_ADMIN: '/bmall/#/',
  ROLE_SYS_OPERATOR: '/bmall/#/',
  ROLE_STORE_ADMIN: '/bmall/#/'
}

export interface SessionAction extends Action<ACTION_NAME> {}

export interface Authenticated extends SessionAction {
  type: AUTHENTICATED
  data: User
}

export interface SaveUser extends SessionAction {
  data: User
}

function saveUser(user): SaveUser {
  return {
    type: SAVE_USER,
    data: user
  }
}
function clearUser(): SessionAction {
  return {
    type: CLEAR_USER
  }
}

function authenticate_failed(): SessionAction {
  return {
    type: AUTHENTICATE_FAILED
  }
}

function authenticated(user: User): Authenticated {
  return {
    type: AUTHENTICATED,
    data: user
  }
}

/**
 *
 * @param login_name 用户名（账户登录）| 手机号（验证码登录）
 * @param password 密码（账户登录）| 验证码（验证码登陆）
 * @param loginWay 登录方式
 */
export const accountLogin = (
  login_name: string,
  password: string,
  loginWay: LoginWay = 'account'
): ThunkAction<Promise<void>, {}, {}, SessionAction> => (
  dispatch: ThunkDispatch<{}, {}, SessionAction>
): Promise<void> => {
  return (loginWay === 'account'
    ? fetchAccountLogin({ login_name, password })
    : loginByVerifyCode(login_name, password)
  ).then(
    (res) => {
      let user = res.data
      message.success('登录成功！')
      dispatch(authenticated(user))
      dispatch(loadCurrentUser())
    },
    (reason) => {
      message.warning('登录失败！' + reason.message)
      console.log(`rejected for `, reason)
      dispatch(authenticate_failed())
    }
  )
}

export const loadCurrentUser = (): ThunkAction<
  Promise<void>,
  {},
  {},
  SessionAction
> => (dispatch: ThunkDispatch<{}, {}, SessionAction>): Promise<void> => {
  return fetchLoadCurrentUser().then(
    (user) => {
      if (user.roles && Cookies.get('authToken')) {
        for (let i = 0; i < user.roles.length; i++) {
          const cur = someRolesToRedirect[user.roles[i]]
          if (cur !== undefined) {
            window.location.href = window.location.origin + cur
            return
          }
        }
      }
      dispatch(saveUser(user))
    },
    (err) => {
      console.log(err)
      console.log(typeof err)
      dispatch(authenticate_failed())
    }
  )
}

export const logout = (): ThunkAction<Promise<void>, {}, {}, SessionAction> => (
  dispatch: ThunkDispatch<{}, {}, SessionAction>
): Promise<void> => {
  const { domain } = getConfig(process.env.REACT_APP_MODE)
  if (Cookies.get('authToken')) {
    Cookies.remove('authToken', { path: '/', domain: domain })
  }
  return fetchLogout().then(() => {
    dispatch(clearUser())
  })
}
