import Immutable from 'immutable';

export function doReducer(state, action, reducer_table) {
    const real_state = state || Immutable.Map();
    const reducer = reducer_table[action.type];
    if (reducer) {
        return reducer(real_state, action.data);
    }
    return real_state;
}
