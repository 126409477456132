import numeral from 'numeral';
import Pie from './Pie';
import Line from './Line';
import Bar from './Bar';

const yuan = val => `¥ ${numeral(val).format('0,0.00')}`;

const Charts = {
  yuan,
  Pie,
  Line,
  Bar
};

export {
  Charts as default,
  yuan,
  Pie,
  Line,
  Bar
};
