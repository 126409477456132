import * as consts from './constants';
import { AnyAction, Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
    ACTION_NAME,
    ResetQueryResult
} from "./constants";

export interface QueryResultAction extends Action<ACTION_NAME> {}


export interface ResetAction extends QueryResultAction {
    type: ResetQueryResult;
    data: any;
}

function reset( data ): ResetAction {
    return {
        type: ResetQueryResult,
        data: data
    };
}

export const requery = (
    listName: string,
    page:number,
    queryFn: (params: any,page:number ) => any,
    queryOption?: any
): ThunkAction<Promise<void>, {}, {}, AnyAction> => (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
): Promise<void> => {
        return queryFn(queryOption, page ).then((json)=>{
            if(json.data instanceof Array){
                dispatch(reset({listName, json:{content:json.data}}));
            }else{
                dispatch(reset({listName, json:json.data}));
            }
        });
    }

/*
export function requery(listName, page=0, queryFn=null, queryOption={}) {
    return (dispatch) => {
        queryFn && queryFn(queryOption, page, (json) => {
            dispatch( { type: consts.actionNames.ResetQueryResult,
                        data: { listName, json }
                    });
        });
    };
}
*/
export function destroy(listName) {
    return {
        type: consts.actionNames.DestroyQueryResult,
        data: listName
    };
}

export function prepend(listName, record) {
    return { type: consts.actionNames.PrependRecord,
             data: { listName,
                     record
                   }
           };
}

export function append(listName, record) {
    return { type: consts.actionNames.AppendRecord,
        data: { listName,
            record
        }
    };
}
export function prependArray(listName, record) {
    return { type: consts.actionNames.PrependRecordList,
        data: { listName,
            record
        }
    };
}
export function deleteRecord(listName, id) {
    return { type: consts.actionNames.DeleteRecord,
        data: { listName,
            id
        }
    };
}

export function updateRecord(listName, record) {
    return { type: consts.actionNames.UpdateRecord,
             data: { listName,
                     record
                   }
           };
}

export function updateRecordList(listName,list,record){
    return {
        type:consts.actionNames.UpdateRecordList,
        data:{listName,list,record}
    }
}
