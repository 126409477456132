/**
 * Created by Lily on 2019/7/10.
 */

import RestResource from 'rest-resource'
import { User, PartyRole } from './type'
const api = '/authserv'
//登录
export function fetchAccountLogin(params) {
  return RestResource.client
    .post(`${api}/session`)
    .with_param(params)
    .should_raise_http_error()
    .execute()
}
//退出
export function fetchLogout() {
  return RestResource.client.delete(`${api}/session`).execute()
}
//加载当前用户
export async function fetchLoadCurrentUser(): Promise<User> {
  const res = await RestResource.client
    .get(`${api}/profile`)
    .should_raise_http_error()
    .execute()
  const { userId, ...other } = res.data
  return { id: userId, ...other }
}

/**
 * 根据手机号获取验证码
 * @param mobilePhone 手机号
 */
export async function sendVerifyCode(mobilePhone: string) {
  const res = await RestResource.client
    .post('/smsVerifyCode')
    .with_param({ mobilePhone })
    .should_raise_http_error()
    .execute()
  return res
}

/**
 * 通过手机验证码登录
 * @param mobilePhone 手机号
 * @param verifyCode 验证码
 */
export async function loginByVerifyCode(
  mobilePhone: string,
  smsVerifyCode: string
) {
  // const partyRole: PartyRole = 'CUSTOMER'
  return RestResource.client
    .post('/session/byVerifyCode')
    .with_param({ mobilePhone, smsVerifyCode })
    .should_raise_http_error()
    .execute()
}
