import React, { Component, Fragment } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import RestResource from "rest-resource";
import { message } from "antd";
import { logout } from "./user/action";

interface IProps {
  host: string;
  pathPrefix: string;
  logout: () => {};
}

interface OwnProps {
  host: string;
  pathPrefix: string;
}

class RestClient extends Component<IProps> {
  componentWillMount() {
    const { host, pathPrefix } = this.props;
    RestResource.init(host, {
      pathPrefix: pathPrefix,

      onServerError: err => {
        console.log(`${err.error_code}: ${err.message}`);
        const msg = err.message || "服务器错误，请联系管理员";
        message.info(msg);
      },

      onNetworkError: (errMsg, err) => {
        if (err && err.status_code === 401) {
          message.info("您的账号已失效，如有疑问请联系管理员！");
        } else if (err && err.status_code >= 500) {
          console.log(errMsg);
          message.info("服务器错误，请稍后再试或联系管理员");
        } else {
          message.info(errMsg);
        }
      },
      // 登录失效
      onUnAuthorizedError: errMsg => {
        this.props.logout();
      }
    });
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<{}, {}, any>,
  ownProps: OwnProps
) {
  return {
    logout: () => dispatch(logout())
  };
}
export default connect(
  null,
  mapDispatchToProps
)(RestClient);
