/**
 * Created by Lily on 2019/7/12.
 */

import React from "react";
import {Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { AuthStatus } from "./../type";
import PageLoading from './../../components/PageLoading';

const isAuth = (currentUser,authority)=>{
    if( authority ){
        const { roles } = currentUser;
        let auth = false;
        if( !!roles ) {
            for(let i of roles){
                if(authority.includes(i)){
                    auth = true;
                }
            }
        }
        return auth;
    }else {
        return true;
    }
}

const AuthorizedRoute = ({component: Component,
    routes,
    authority,
    sessionStatus,
    currentUser,
    ...rest})=> {
    return (
        <Route
            {...rest}
            render={props => {
                switch (sessionStatus) {
                    case AuthStatus.UNKNOWN:
                        return <PageLoading />;
                    case AuthStatus.SUCCEED:
                        return isAuth(currentUser,authority) ? <Component {...props} routes={routes}/>:
                            <Redirect to="/exception/403"/>;
                    case AuthStatus.FAILED:
                        return <Redirect to="/user/login"/>
                }
            }}
        />
    );
}
function mapStateToProps(state) {
    const { authenticated,user } = state.user;
    return {
        sessionStatus: authenticated,
        currentUser:user
    };
}

export default connect(
    mapStateToProps,
    null
)(AuthorizedRoute);
