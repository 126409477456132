/**
 * Created by Lily on 2019/4/15.
 */

import { combineReducers } from "redux";
import global from "./global";
import queryResult from "./../components/QueryResult";
import user from "./../user/reducer";

export default combineReducers({
    queryResult : queryResult.reducers,
    global,
    user
});