import BasicLayout from './layouts/BasicLayout';
import SiderLayout from './layouts/SiderLayout';
import ContentLayout from './layouts/ContentLayout';
import Login from './user/login';
import Home from './pages/home'
import Commission from './channel/commission';
import Product from './channel/product';
import ProductAuthorizedOrg from './channel/product/authorizedOrg';
import ProductOrgPolicy from './channel/product/policy';
import Sale from './channel/sale';
import Unauthorized from './pages/other/403';



export const routes = [
    {
        path: "/",
        component: BasicLayout,
        requiresAuth: true,
        routes: [
            {
                path: "/exception",
                requiresAuth: true,
                component: ContentLayout,
                routes: [
                    {
                        path: "/exception/403",
                        exact: true,
                        component: Unauthorized
                    }
                ]
            },
            {
                path: '/home',
                component: ContentLayout,
                requiresAuth: true,
                routes: [
                    {
                        path: '/home',
                        component: Home,
                    }
                ]
            }, {
                path: '/channel',
                component: SiderLayout,
                authority: ['CHANNEL_ADMIN', 'ORG_ADMIN'],
                requiresAuth: true,
                routes: [
                    {
                        path: '/channel/',
                        exact: true,
                        requiresAuth: true,
                        // component: Commission
                        redirectUrl: '/channel/commission'
                    }, {
                        path: '/channel/commission',
                        exact: true,
                        requiresAuth: true,
                        component: Commission,
                        authority: ['CHANNEL_ADMIN', 'ORG_ADMIN'],
                    }, {
                        path: '/channel/sale',
                        exact: true,
                        requiresAuth: true,
                        component: Sale,
                        authority: ['ORG_ADMIN'],
                    }, {
                        path: '/channel/product/authOrg',
                        component: ProductAuthorizedOrg,
                        requiresAuth: true,
                        authority: ['CHANNEL_ADMIN']
                    }, {
                        path: '/channel/product/policy/:id',
                        component: ProductOrgPolicy,
                        requiresAuth: true,
                        authority: ['CHANNEL_ADMIN']
                    }, {
                        path: '/channel/product',
                        requiresAuth: true,
                        component: Product,
                        authority: ['CHANNEL_ADMIN'],
                    }
                ]
            },
        ]
    }, {
        path: "/user",
        component: ContentLayout,
        requiresAuth: false,
        routes: [
            {
                path: "/user/login",
                component: Login
            }
        ]
    },
];