/**
 * Created by Lily on 2019/7/10.
 */
import { doReducer } from './../reducers/reducer_helper';
import { AnyAction } from "redux";
import {
  Authenticated,
  SaveUser,
  SessionAction,
} from "./action";
import { UserState, AuthStatus } from "./type";
import {
  AUTHENTICATED,
  SAVE_USER,
  CLEAR_USER,
  AUTHENTICATE_FAILED
} from "./constant";

export const initState: UserState = {
  authenticated: AuthStatus.UNKNOWN,
};

export const REDUCER_TABLE = {};

REDUCER_TABLE[AUTHENTICATED] = (state:UserState, data:Authenticated) => {
  return {
    ...state,
    authenticated: AuthStatus.SUCCEED,
    user: data
  }
};

REDUCER_TABLE[SAVE_USER] = (state, data:SaveUser) => {
  return {
    ...state,
    authenticated: AuthStatus.SUCCEED,
    user: data
  };
};

REDUCER_TABLE[CLEAR_USER] = (state, data) => {
  return { ...initState, authenticated: AuthStatus.FAILED }
};

REDUCER_TABLE[AUTHENTICATE_FAILED] = (state, data) => {
  return { ...initState, authenticated: AuthStatus.FAILED }
};


export default function reducers( state : UserState = initState , action:SessionAction | AnyAction) {
  return doReducer(state, action, REDUCER_TABLE);
}
