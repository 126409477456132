import React, { Component } from 'react'
import { ThunkDispatch } from 'redux-thunk'
import { Form, Icon, Input, Button } from 'antd'
import logo from './../../assets/logo.png'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { FormComponentProps } from 'antd/lib/form'
import './index.less'
import { accountLogin } from '../action'
import { AuthStatus } from '../type'
import MobilePhoneLogin from './MobilePhoneLogin'
import { LoginWay } from '../type'

interface LoginProps extends FormComponentProps {
  authenticated: AuthStatus
}

interface LoginState {
  loginWay: LoginWay
}

export type ToggleLoginWay = (way: LoginWay) => () => void

export interface FromDataType {
  userName: string
  password: string
}

interface DispatchProps {
  login: (username: string, password: string) => void
}

type Props = LoginProps & DispatchProps

class LoginForm extends Component<Props, LoginState> {
  readonly state: LoginState = {
    loginWay: 'account'
  }

  handleSubmit: any = (e) => {
    e.preventDefault()
    this.props.form.validateFields((err, values: FromDataType) => {
      if (!err) {
        const { login } = this.props
        login(values.userName, values.password)
      }
    })
  }

  toggleLoginWay: ToggleLoginWay = (way: LoginWay) => () => {
    this.setState({
      loginWay: way
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { authenticated } = this.props
    const { loginWay } = this.state
    if (authenticated == AuthStatus.SUCCEED) {
      return <Redirect to="/home" />
    }

    return (
      <div className="user-login">
        <div className="content">
          <div className="top">
            <div className="header">
              <Link to="/">
                <img alt="logo" className="logo" src={logo} />
              </Link>
            </div>
          </div>
          <div
            style={{ display: loginWay === 'mobilePhone' ? 'block' : 'none' }}
          >
            <MobilePhoneLogin toggleLoginWay={this.toggleLoginWay} />
          </div>
          <Form
            onSubmit={this.handleSubmit}
            className="login-form"
            style={{ display: loginWay === 'mobilePhone' ? 'none' : 'block' }}
          >
            <Form.Item>
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: '请输入用户名!' }]
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="用户名"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: '请输入密码!' }]
              })(
                <Input
                  size="large"
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="password"
                  placeholder="密码"
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className="handle">
                <span
                  className="toggle-login-way"
                  onClick={this.toggleLoginWay('mobilePhone')}
                >
                  手机验证码登录
                </span>
                <a className="login-form-forgot" href="">
                  忘记密码？
                </a>
              </div>
              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => {
  const { user } = state
  return {
    authenticated: user.authenticated
  }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    login: async (username, password) => {
      await dispatch(accountLogin(username, password))
      console.log('Login completed [UI]')
    }
  }
}
const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm)
export default connect(mapStateToProps, mapDispatchToProps)(WrappedLoginForm)
