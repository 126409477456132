import React, { useState, useEffect } from 'react'
import { ToggleLoginWay } from './index'
import { Form, Icon, Input, Button, Row, Col, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { validateMobilePhone } from 'utils/validate'
import { sendVerifyCode } from 'user/service'
import { useCountDown } from 'hooks/useCountDown'
import { accountLogin } from '../action'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

interface Props extends FormComponentProps {
  toggleLoginWay: ToggleLoginWay
  login: (mobilePhone: string, verifyCode: string) => void
}

const VerifyCodeButton: React.FC<any> = (props) => {
  const [countTime, setCountTime] = useCountDown()
  const [loading, setLoading] = useState(false)
  const isLocked = countTime > 0 || loading
  const sendCode = () => {
    if (isLocked) {
      return
    }
    props.getMobilePhone(async (mobilePhone) => {
      try {
        setLoading(true)
        await sendVerifyCode(mobilePhone)
        message.success('发送成功')
        setCountTime(60)
      } catch (e) {
        console.error(e)
        message.error('发送失败')
      } finally {
        setLoading(false)
      }
    })
  }
  return (
    <Button
      disabled={isLocked}
      onClick={sendCode}
      size="large"
      loading={loading}
    >
      {countTime > 0 ? `${countTime}s` : '发送验证码'}
    </Button>
  )
}

const MobilePhoneLoginForm: React.FC<Props> = (props) => {
  const getMobilePhone = (cb) => {
    props.form.validateFields(['mobilePhone'], (err, values) => {
      !err && cb(values['mobilePhone'])
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        props.login(values.mobilePhone, values.verifyCode )
      }
    })
  }
  const { getFieldDecorator } = props.form
  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Form.Item>
        {getFieldDecorator('mobilePhone', {
          rules: [{ required: true, validator: validateMobilePhone }]
        })(
          <Input
            size="large"
            prefix={<Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="手机号"
            allowClear={true}
            maxLength={11}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Row type="flex" justify="space-between">
          <Col span={14}>
            {getFieldDecorator('verifyCode', {
              rules: [{ required: true, message: '请输入验证码' }]
            })(
              <Input
                size="large"
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="text"
                placeholder="验证码"
              />
            )}
          </Col>
          <Col>
            <VerifyCodeButton getMobilePhone={getMobilePhone} />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item>
        <div className="handle">
          <span
            className="toggle-login-way"
            onClick={props.toggleLoginWay('account')}
          >
            <Icon type="arrow-left" />
            账号登录
          </span>
        </div>
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          登录
        </Button>
      </Form.Item>
    </Form>
  )
}

const MobilePhoneLogin = Form.create<Props>({ name: 'mobilePhoneLogin' })(
  MobilePhoneLoginForm
)
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    login: async (mobilePhone, verifyCode) => {
      await dispatch(accountLogin(mobilePhone, verifyCode, 'mobilePhone'))
    }
  }
}
export default connect(null, mapDispatchToProps)(React.memo(MobilePhoneLogin))
