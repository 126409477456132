import React, {Component} from "react";
import {connect} from "react-redux";
import {Layout, Icon, message} from "antd";
import "./Header.less";
import {changeCollapsed} from "../reducers/global/action";
import {logout} from "../user/action";
import ConfigContext from './../ConfigContext';
import MenuWrapper from "./../components/MenuWrapper";

const {Header} = Layout;
interface IProps {
    logo: string;
    isMobile: boolean;
    collapsed: boolean;
    orgName?: string;
    dispatch: any;
    location: any;
    // onMenuCollapse ?:(collapsed:boolean) => void
}
interface IState {
}

const TopMenuWrapper = MenuWrapper.TopMenuWrapper;

class HeaderView extends Component<IProps, IState> {

    handleMenuCollapse = collapsed => {
        const {dispatch} = this.props;
        dispatch(changeCollapsed(collapsed));
    };

    renderRightContent() {
        const { dispatch,location,isMobile,collapsed } = this.props;
        return (
            <ConfigContext.Consumer>
                {context => (
                    <div className="right-container">
                        <TopMenuWrapper
                            isMobile= { isMobile }
                            collapsed = { collapsed }
                            menuData={ context.menu }
                            pathname={ location.pathname }
                            onCollapse={ this.handleMenuCollapse }
                        />
                        <a onClick={() => {
          dispatch(logout());
          message.success("退出成功！");
        }}
                        >
                            退出
                        </a>
                    </div>

                )}
            </ConfigContext.Consumer>



        );
    }

    render() {
        const {logo, isMobile, orgName} = this.props;
        let mobile_cls = isMobile ? "header-mobile" : "";
        return (
            <div className={`header-container ${mobile_cls}`}>
                <Header>
                    <div className="logo-container">
                        <div className="logo-container-img">
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className="logo-container-company">{orgName}</div>
                    </div>
                    {this.renderRightContent()}
                </Header>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    const {global, user} = state;
    const orgName = user.user ? user.user.orgName : "";
    return {
        collapsed: global.collapsed,
        isMobile: global.isMobile,
        orgName: orgName
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => {
            dispatch(logout());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderView);
