/**
 * Created by Lily on 2019/7/3.
 */

import React,{ Component } from 'react';
import {Row, Col,Button} from 'antd';
import { connect } from 'react-redux';
import { logout } from './../../user/action';
import Exception from './../../components/Exception';
import { ExceptionProps } from './../../components/Exception'

interface IProps extends ExceptionProps{
    clearUser: ()=>void;
    history:any;
}

class Unauthorized extends React.Component<IProps, any> {

    handleBack = ()=>{
        this.props.clearUser()
        this.props.history.push('/user/login')
    };

    render() {
        return (
            <Exception
                type="403"
                desc="您无权访问，如有问题请联系管理员！"
                actions={ <Button  type="primary" onClick={this.handleBack } >返回登录 </Button> }
            />
        )
    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
        clearUser: ()=>dispatch( logout() )
    }
}
export default connect(null,mapDispatchToProps)(Unauthorized)