import { AnyAction, Action } from "redux";
import { ACTION_NAME, CHANGE_MOBILE, CHANGE_COLLAPSED } from './constant';

export interface GlobalAction extends Action<ACTION_NAME> {}

//define action interfaces
export interface ChangeCollapsed {
    type: CHANGE_COLLAPSED;
    data: boolean;
}
//define action interfaces
export interface ChangeMobile {
    type: CHANGE_MOBILE;
    data: boolean;
}
//define actions
export function changeCollapsed(collapsed: boolean) :ChangeCollapsed{
    return {
        type: CHANGE_COLLAPSED,
        data: collapsed
    };
};

//define actions
export function changeMobile(isMobile) : ChangeMobile{
    return {
        type: CHANGE_MOBILE,
        data: isMobile
    };
}