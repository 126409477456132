import React, {PureComponent} from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

const {SubMenu} = Menu;

interface IProps {
    theme?: "light" | "dark" | undefined;
    mode?: 'vertical' | 'vertical-left' | 'vertical-right' | 'horizontal' | 'inline';
    handleOpenChange?: (openKeys: string[]) => void;
    style?: React.CSSProperties;
    menuData: any;
    pathname?:string;
}

export default class BaseMenu extends PureComponent<IProps,any> {

    renderMenuItem(data) {
        return data.map((item) => {
            if (item.subMenu) {
                return (
                    <SubMenu key={`sub${item.key}`} className="submenu-title-wrapper"
                        title={
                        <span >
                            { item.name }
                        </span> }>
                        {
                            this.renderMenuItem(item.subMenu)
                        }
                    </SubMenu>
                )
            }
            return <Menu.Item key={ item.key }>
                    { item.isExternal? <a href={item.link} target="_blank">{item.name}</a>:
                        <Link to={ item.link }>{item.name}</Link>}
                </Menu.Item>
        })
    }

    getSelectedMenu(pathname){
        return pathname.split('/').map(item=>`/${item}`)
    }

    render() {
        const {
            handleOpenChange, style, theme,
            mode, menuData,pathname
        } = this.props;
        let selectedMenu = this.getSelectedMenu(pathname);
        return (
            <Menu
                key="Menu"
                mode={mode}
                theme={theme}
                onOpenChange={handleOpenChange}
                selectedKeys={selectedMenu}
                style={style}
            >
                { this.renderMenuItem(menuData) }
            </Menu>
        );
    }
}
