import React, {Component} from 'react';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
    Legend,
} from 'bizcharts';
import DataSet from "@antv/data-set";

interface IProps {
    height: number;
    padding?:[number, number, number, number];
    hasLegend?: boolean;
    percent?: number;
    data?: Array<{
        item: string | string;
        value: number;
    }>;
    tooltip?: boolean;
}
interface IState {
}
class Pie extends Component<IProps,IState> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        const {data} = this.props;
        if (data !== preProps.data) {

        }
    }

    componentWillUnmount() {

    }

    render() {
        const {DataView} = DataSet;
        const {
            data,
            padding='auto',
            tooltip = true,
            height
        } = this.props;
        const dv = new DataView();
        dv.source(data).transform({
            type: "percent",
            field: "value",
            dimension: "item",
            as: "percent"
        });

        return (
            <div>
                <div>
                    <Chart
                        height={height}
                        data={dv}
                        padding={padding}
                        forceFit
                    >
                        <Coord type="theta" />
                        <Axis name="value" />
                        {!!tooltip && <Tooltip showTitle={false}/>}
                        <Geom
                            type="intervalStack"
                            position="percent"
                            style={{ lineWidth:1, stroke: '#fff' }}
                            tooltip={ [  'item*percent',
                                (x, p) => ({
                                    name: x,
                                    value: `${(p * 100).toFixed(2)}%`,
                                }),
                            ] }
                            color={['item', ['#fd6a37', '#5fb7e5','#6d53dc']]}
                        >
                            <Label
                                content="percent"
                                offset={-15}
                                htmlTemplate={(text, item, index)=>{
                                    var point = item.point; // 每个弧度对应的点
                                    // 自定义 html 模板
                                    var percent = point['percent'];
                                    percent = (percent * 100).toFixed(2) + '%';
                                    return '<div style="text-align: center;width: 90px;color:#fff;">' +
                                     '<span class="title" >' + point['item'] + '</span><br><span >' + percent + '</span></div>';
                                }}
                            />
                        </Geom>

                    </Chart>
                </div>
            </div>
        );
    }
}

export default Pie;
