/**
 * Created by Lily on 2019/7/3.
 */

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { fetchQuerySale } from '../service';
import QueryResult from './../../components/QueryResult';
import { Input } from 'antd';

const { Search } = Input;

const columns = [
    {
        title: '头像',
        dataIndex: 'img',
        render:(text)=>{
            return <img src={text} alt="" style={{width:60,height:60}}/>
        }
    },{
        title: '销售账号',
        dataIndex: 'userAccount'
    }, {
        title: '销售姓名',
        dataIndex: 'name'
    }, {
        title: '电话',
        dataIndex: 'phone'
    }, {
        title: '微信号',
        dataIndex: 'weixin'
    }, {
        title: '上级销售账号',
        dataIndex: 'superUserAccount'
    }, {
        title: '上级销售姓名',
        dataIndex: 'superUserName'
    }, {
        title: '注册时间',
        dataIndex: 'regTime',
        render:(text)=>{
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
        }
    }
]

export interface DataItem {
    key: number;
    img: string;
    userAccount:string;
    name:string;
    phone:string;
    weixin:string;
    superUserAccount:string;
    superUserName:string;
    regTime:Date;
}

interface IProps {
}

interface IState {
    queryOption: { [key: string]: string };
}

const MyTable = QueryResult.ResultTable();
const MyTableQueryResult = QueryResult.withQueryResultView(fetchQuerySale, 'list.sale')(MyTable);

class SaleTable extends Component<IProps,IState>{

    state: IState = {
        queryOption: {
            keyword:''
        }
    };
    handleSearch = (value) => {

        let data = Object.assign({}, this.state.queryOption, {keyword: value})
        this.setState({
            queryOption: data
        })

    };
    render() {
        return (
            <div>
                <div style={{marginBottom:20}}>
                    <Search
                        placeholder="请输入关键字搜索"
                        onSearch={value => this.handleSearch(value)}
                        style={{ width: 200 }}
                    />
                </div>
                <MyTableQueryResult
                    queryOption = { this.state.queryOption }
                    resultProps={ {
                        columns: columns,
                        scroll: { x: 1000 },
                        size:"middle"
                    } }/>
            </div>
        )
    }
}

export default SaleTable