import { doReducer } from '../../reducers/reducer_helper';
import Immutable from 'immutable';
import { actionNames } from './constants';

export const REDUCER_TABLE = {};

REDUCER_TABLE[actionNames.ResetQueryResult] = (state, data) => {
    const { listName, json } = data;
    return state.mergeIn([listName],
                         Immutable.fromJS(json)
                        );
};

REDUCER_TABLE[actionNames.DestroyQueryResult] = (state, data) => {
    return state.delete(data);
};

REDUCER_TABLE[actionNames.AppendRecord] = (state, data) => {
    const { listName, record } = data;
    return state.updateIn([listName, 'content'],
        Immutable.List(),
        (list) => list.push( Immutable.fromJS(record) )
    );
};
REDUCER_TABLE[actionNames.PrependRecord] = (state, data) => {
    const { listName, record } = data;
    return state.updateIn([listName, 'content'],
        Immutable.List(),
        (list) => list.unshift( Immutable.fromJS(record) )
    )
        // .updateIn([listName, 'number'],val=>0);
};
REDUCER_TABLE[actionNames.PrependRecordList] = (state, data) => {
    const { listName, record } = data;
    let newList:Immutable.Map<string, any>[] = [];
    record.forEach((item)=>{
        newList.push(Immutable.fromJS(item));
    })
    return state.updateIn([listName, 'content'],
        Immutable.List(),
        (list) => list.unshift(...newList )
    );
};


REDUCER_TABLE[actionNames.DeleteRecord] = (state, data) => {
    const { listName, id } = data;
    return state.updateIn([listName, 'content'],
                          (list) => findAndDelete(list, rec => rec.get('id') === id )
    );
};

REDUCER_TABLE[actionNames.UpdateRecord] = (state, data) => {
    const { listName, record } = data;
    return state.updateIn([listName, 'content'],
                          (list) => findAndReplace(list,
                                                    (rec) => rec.get('id') === record.id,
                                                    Immutable.fromJS(record) )
                         );
};

REDUCER_TABLE[actionNames.UpdateRecordList] = (state, data) => {
    const { listName, list } = data;
    let newMap =new Map();
    list.forEach((item)=>{
        newMap.set(item.id,item);
    });
    return state.updateIn([listName, 'content'],
        (oldList) => findAndUpdate(oldList,newMap)
    );
};

export default function queryResultReducers( state = Immutable.fromJS({}), action) {
    return doReducer(state, action, REDUCER_TABLE);
}
function findAndUpdate(oldList,newMap) {
    const newList = oldList.map((item)=>{
        const newItem = newMap.get(item.get('id'));
        if (newItem != null) {
            item = item.merge(newItem);
        }
        return item;
    });

    return newList;
}

function findAndReplace(list, predicate, object) {
    const idx = list.findIndex(predicate);
    if (idx >= 0) {
        return list.set(idx, object);
    } else {
        return list;
    }
}

function findAndDelete(list, predicate) {
    const idx = list.findIndex(predicate);
    return idx >= 0 ? list.delete(idx) : list;
}



