import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter,Switch } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import RestClient from "./RestClient";
import App from "./App";
import "./index.css";
import { ConfigProvider } from 'antd';
// import zhCN from 'antd/es/locale-provider/zh_CN';
import zhCN from 'antd/es/locale/zh_CN';

import moment from 'moment';
import 'moment/locale/zh-cn';

// moment.locale('zh-cn');

if (process.env.NODE_ENV === 'development') {
    // mock.start()
}


ReactDOM.render(
  <Provider store={store}>
    <RestClient host="" pathPrefix="/api">
        <ConfigProvider locale={zhCN}>
            <BrowserRouter>
                <Switch>
                    <App />
                </Switch>

            </BrowserRouter>
        </ConfigProvider>

    </RestClient>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
