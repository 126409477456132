import React, {ReactNode, SFC} from 'react';
import {BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";
import AuthorizedRoute from './user/Authorized/AuthorizedRoute';

const RouteWithSubRoutes = ({
    component: Component,
    path,
    routes,
    redirectUrl,
    exact,
    authority,
    requiresAuth,
    ...otherProps
}) => {
    return <Route
        path={ path }
        exact = { exact }
        render={ props => {
            return ( redirectUrl ? <Redirect to={redirectUrl}/> :
                    requiresAuth?<AuthorizedRoute
                        path={ path }
                        component={ Component }
                        routes={ routes }
                        authority={ authority }
                        { ...otherProps }
                    />:<Component {...props} routes={routes}/>
            )
        }}
    />

};
export default RouteWithSubRoutes;
