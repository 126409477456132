import React from 'react';
import { Table } from 'antd';


export default function ResultTable() {
    return ({ result, pagination,resultProps , columns, loading}) =>  {
        return (
            <Table dataSource={ result }
              columns={ columns }
                   loading={loading}
              rowKey="id"
              pagination={ pagination }

                   bordered={true}
                   {...resultProps}
            />
        );
    }
}
