import React, {Component} from 'react';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Coord,
    Label,
} from 'bizcharts';
import { yuan } from './../index';

interface IProps {
    height:number;
    padding?:[number, number, number, number];
    transpose?:boolean;
    label?:boolean;
    tooltipFormat:any;
    data?: Array<{
        item: number | string;
        value: number;
    }>;
}
interface IState {

}
class Bar extends Component<IProps,IState> {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        const {data} = this.props;
        if (data !== preProps.data) {

        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            data,
            height,
            padding='auto',
            transpose,
            label,
            tooltipFormat
        } = this.props;

        return (
            <div>
                <div>
                    <Chart height={height} data={data} padding={padding} forceFit>
                        {transpose&&<Coord transpose />}
                        <Axis name="item" />
                        <Axis name="value" />
                        <Tooltip />
                        <Geom type="interval"  position="item*value"
                              color={'#fd6a37'}
                              tooltip={ [ "item*value",tooltipFormat ] } >
                            { label ? <Label offset={-20} content={['item*value', (item, value) => yuan(value)]} />:''}
                        </Geom>

                    </Chart>
                </div>
            </div>
        );
    }
}

export default Bar;
