import React, {Component} from 'react';
import {Layout} from 'antd';
import {connect} from 'react-redux';
import {ContainerQuery} from 'react-container-query';
import Media from 'react-media';
import classNames from 'classnames';
import Header from './Header';
import logo from '../assets/logo.png';
import { changeCollapsed,changeMobile } from '../reducers/global/action';
import RouteWithSubRoutes from './../RouteWithSubRoutes';

const query = {
    'screen-xs':  {
        maxWidth: 575,
    },
    'screen-sm':  {
        minWidth: 576,
        maxWidth: 767,
    },
    'screen-md':  {
        minWidth: 768,
        maxWidth: 991,
    },
    'screen-lg':  {
        minWidth: 992,
        maxWidth: 1199,
    },
    'screen-xl':  {
        minWidth: 1200,
        maxWidth: 1599,
    },
    'screen-xxl': {
        minWidth: 1600,
    },
};

class BasicLayout extends Component {
    componentDidMount() {
        const {isMobile, dispatch} = this.props;
        dispatch(changeMobile(isMobile));
    }

    componentDidUpdate(preProps) {
        const {collapsed, isMobile, dispatch} = this.props;

        if (isMobile && !preProps.isMobile && !collapsed) {
            this.handleMenuCollapse(false)
        }
        if (isMobile !== preProps.isMobile) {
            dispatch(changeMobile(isMobile));
        }
    }

    handleMenuCollapse = collapsed => {
        const {dispatch} = this.props;
        dispatch(changeCollapsed(collapsed));
    };

    render() {
        const {
                  isMobile,
                  collapsed,
                  routes
        }      = this.props;
        const layout = (
            <Layout
                style={{minHeight: '100vh'}}>
                <Header
                    logo={logo}
                    isMobile={isMobile}
                    collapsed={collapsed}
                    {...this.props}
                />
                <Layout>
                    {
                        routes && routes.map((route, i) => {
                            return (
                                <RouteWithSubRoutes key={i} {...route} />)
                        })
                    }
                </Layout>

            </Layout>
        );
        return (
            <React.Fragment>
                <ContainerQuery query={query}>
                    {params => {
                        return (
                            <div className={classNames(params)}>{layout}</div>
                        )
                    }}
                </ContainerQuery>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {global, user} = state;
    const orgName                 = user.user ? user.user.orgName : "";
    return {
        collapsed: global.collapsed,
        orgName:   orgName
    };
};

export default connect(
    mapStateToProps,
    null
)((props => (
    <Media query="(max-width: 599px)">
        {isMobile => <BasicLayout className="basic-layout" {...props} isMobile={isMobile}/>}
    </Media>
)));
