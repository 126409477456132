export const actionNames= {
    ResetQueryResult: 'query_result.reset_query_result',
    DestroyQueryResult: 'query_result.destroy',
    AppendRecord: 'query_result.append_record',
    PrependRecord: 'query_result.prepend_record',
    PrependRecordList: 'query_result.prepend_record_list',
    DeleteRecord: 'query_result.delete_record',
    UpdateRecord: 'query_result.update_record',
    UpdateRecordList:'query_result.update_record_list',
};

export const ResetQueryResult = "query_result.reset_query_result";
export type ResetQueryResult = typeof ResetQueryResult;

export type ACTION_NAME = ResetQueryResult;