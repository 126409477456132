import React from 'react';
import {Layout} from 'antd';
import RouteWithSubRoutes from './../RouteWithSubRoutes';

const { Content } = Layout;

const SiderLayout = ({routes}) => (
    <React.Fragment>
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{padding: 24, background: '#ffffff'}}>
                {
                    routes && routes.map((route, i) => {
                        return (
                            <RouteWithSubRoutes key={i} {...route} />)
                    })
                }
            </Content>
        </Layout>
    </React.Fragment>
);
export default SiderLayout;
