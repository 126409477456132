
import { fetchQueryOrganizationAll } from '../service';
import React, { Component, Fragment } from 'react';
import { Modal, message, Table  } from 'antd';

interface ModalProps {
    selected: any[];
    channelId:string;
    visible:boolean;
    onCancel:()=>void;
    onOk:(keys)=>void;
}

class OrgModal extends Component<ModalProps>{
    state = {
        selectedRowKeys: [],
        loading: false,
        sourceList:[],
        list:[],
    };
    channelId = this.props.channelId;

    componentWillReceiveProps( nextProps ){
        if( nextProps.selected !== this.props.selected){
            this.handleList( this.state.sourceList )
        }
    }

    componentDidMount(){
        this.queryList()
    }
    queryList(){
        fetchQueryOrganizationAll( this.channelId ).then(res=>{
            this.setState({ sourceList : res.data})
            this.handleList( res.data )
        })
    }
    handleList(list){
        const { selected } = this.props;
        let newList = list.filter( item=> selected.indexOf(item.id) == -1 );
        this.setState({ list : newList })
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    handleOk = ()=>{
        const { selectedRowKeys } = this.state;
        const { onOk } = this.props;

        if(selectedRowKeys.length !=0){
            onOk(selectedRowKeys)
            this.setState({ selectedRowKeys:[] })
        }else{
            message.info('请至少选择一条数据');
        }
    }

    render(){
        const { visible, onCancel, onOk, selected } = this.props;
        const { selectedRowKeys , sourceList, list } = this.state;
        const columns = [
            {
                title: '组织名称',
                dataIndex: 'name'
            },
        ];
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record =>{
                return ({
                    disabled: selected.includes(record.id), // Column configuration not to be checked
                    name: record.name,
                })
            } ,
        };

        return (
            <Modal
                visible={visible}
                title="选择组织"
                okText="确定"
                cancelText="取消"
                onCancel={ onCancel }
                onOk={ this.handleOk }
            >
                <Table  dataSource={ list }
                        rowSelection={rowSelection}
                        columns = {columns}
                        size="small"
                        rowKey = { record => record.id }/>
            </Modal>
        )
    }
}

export default OrgModal;