/**
 * Created by Lily on 2019/7/3.
 */

import React, {Component, Fragment} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {fetchQueryCommission } from '../service';
import QueryResult from './../../components/QueryResult';
import * as actions from './../../components/QueryResult/actions';
import { Category } from './../constants';
import ExportModal from './ExportModal';
import { Upload, Button , message, Modal } from 'antd';


const columns: any[] = [
    {
        title: '分类',
        dataIndex: 'category',
        width: 100,
        fixed: true,
        render:(text)=>Category[text]
    }, {
        title: '产品名称',
        dataIndex: 'productName',
        width: 100,
        fixed: true
    },{
        title: '销售量',
        dataIndex: 'quantity',
        width: 80,
        fixed: true
    }, {
        title: '销售总额',
        dataIndex: 'totalAmount',
        width: 80,
        fixed: true
    }, {
        title: '分佣总额',
        dataIndex: 'totalCommission',
        width: 80,
        fixed: true
    }, {
        title: '渠道',
        children: [
            {
                title: '名称',
                dataIndex: 'commissions.CHANNEL.recipient.party.name',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }, {
                title: '比例',
                dataIndex: 'commissions.CHANNEL.rate',
                width: 80,
                render: (text) =><span>{ text?`${text}%`:'--' }</span>
            }, {
                title: '金额',
                dataIndex: 'commissions.CHANNEL.amount',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }
        ]
    }, {
        title: '组织',
        children: [
            {
                title: '名称',
                dataIndex: 'commissions.ORGANIZATION.recipient.party.name',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }, {
                title: '比例',
                dataIndex: 'commissions.ORGANIZATION.rate',
                width: 80,
                render: (text) =><span>{ text?`${text}%`:'--'  }</span>
            }, {
                title: '金额',
                dataIndex: 'commissions.ORGANIZATION.amount',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }
        ]
    }, {
        title: '上级销售',
        children: [
            {
                title: '名称',
                dataIndex: 'commissions.INVITER.recipient.party.name',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }, {
                title: '比例',
                dataIndex: 'commissions.INVITER.rate',
                width: 80,
                render: (text) =><span>{ text?`${text}%`:'--'  }</span>
            }, {
                title: '金额',
                dataIndex: 'commissions.INVITER.amount',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }
        ]
    }, {
        title: '销售',
        children: [
            {
                title: '名称',
                dataIndex: 'commissions.SALES.recipient.party.name',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }, {
                title: '比例',
                dataIndex: 'commissions.SALES.rate',
                width: 80,
                render: (text) =><span>{ text?`${text}%`:'--' }</span>
            }, {
                title: '金额',
                dataIndex: 'commissions.SALES.amount',
                width: 80,
                render: (text) =><span>{ text || '--' }</span>
            }
        ]
    }, {
        title: '创建时间',
        dataIndex: 'createdTime',
        render: (text) => {
            return moment(text).format("YYYY-MM-DD HH:mm:ss");
        }
    }
]
interface Recipient {
    party: {
        id: string, // id
        name: string, // 名称
        role: string
    },
    commissionType: string,
}
interface RoleType {
    recipient: Recipient;
    rate: number; // 佣金分配比例
    amount: number; // 佣金分配金额
}
interface Commission {
    SALES: RoleType|null;
    CHANNEL: RoleType|null;
    ORGANIZATION: RoleType|null;
    INVITER: RoleType|null;
}

interface IProps {
    // channelId:string;
    doQueryList: ()=>void;
}

interface IState {
    queryOption: {[key: string]: any};
    visible:boolean;
    fileList: any[];
}

const MyTable = QueryResult.ResultTable();
const MyTableQueryResult = QueryResult.withQueryResultView(fetchQueryCommission, 'list.Commission')(MyTable);

class CommissionTable extends Component<IProps,IState> {

    state: IState = {
        visible: false,
        queryOption: {},
        fileList:[]
    };

    showModal = () => {
        this.setState({ visible: true });
    };

    onCancel = () => {
        this.setState({ visible: false });
    };
    showResultModal = (res)=>{
        Modal.info({
            title: '导入结果',
            content: (
                <div>
                    {res.data.map(item=>{
                        return (
                            <div>第{ item.row }行：{ item.message }</div>
                        )
                    })}
                </div>
            ),
            onOk() {},
        })
    }
    render() {
        const props = {
            name: 'file',
            showUploadList:false,
            accept:"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            action: '/api/salesorg/commissions/payments/records',
            onChange: (info)=>{
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    console.log('info-----',info)
                    if( info.file.response.success ){
                        this.showResultModal(info.file.response)
                    }else{
                        message.success(`${info.file.name} 导入失败`);
                    }

                    // message.success(`${info.file.name} 导入成功`);
                    // this.props.doQueryList();
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 导入失败.`);
                }
            },
        };
        return (
            <div>
                <div className="flex-row-end" style={{marginBottom:20}}>
                    <Upload {...props}>
                        <Button type="link" >导入</Button>
                    </Upload>

                    <Button type="primary" onClick={ this.showModal }>导出</Button>
                    <ExportModal
                        visible={this.state.visible}
                        onCancel={this.onCancel}
                    />
                </div>

                <MyTableQueryResult
                    queryOption = { this.state.queryOption }
                    resultProps={ {
                        columns: columns,
                        scroll: { x: 1700 },
                        size:"middle"
                    } }
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        doQueryList:(page = 0,queryOption ={})=>{
            dispatch(actions.requery('list.Commission', page, fetchQueryCommission, queryOption ))
        }
    };
};

export default connect(null,mapDispatchToProps)(CommissionTable);
