//佣金统计类型
export const Category = {
    SalesOrder: "商品销售",
    MonthlySalesSummary: "月度统计"
};
//佣金策略模式
export const CommissionPolicyType = {
    SINGLE_TRADE_AMOUNT : "单笔交易",
    MONTHLY_SALES_AMOUNT : "月销售金额" ,
    MONTHLY_SALES_QUANTITY: "月销售量"
};

export const CommissionQuotaType = {
    revenueRate : 'REVENUE_RATE',//佣金总额为销售额的指定比例
    fixAmount : 'FIX_AMOUNT', //固定佣金金额
}