import MobilePhoneLogin from 'user/login/MobilePhoneLogin'

interface ValidateRules {
  readonly mobilePhone: RegExp
}
export const validateRulesMap: ValidateRules = {
  mobilePhone: /^1\d{10}$/
}

/**
 * 校验手机号
 */
export function validateMobilePhone(rule, value, cb) {
  if (!value) {
    cb('请输入手机号')
  } else if (!validateRulesMap.mobilePhone.test(value)) {
    cb('手机号格式错误')
  } else {
    cb()
  }
}
