import numeral from 'numeral';

export const yuan = val => `¥ ${numeral(val).format('0,0')}`;
export const rate = val => `${val} %`;

export const sum = ( vals:number[] = [] ) => {
    let i = numeral(0);
    vals.map( item =>{
        i = i.add(item)
    } )
    return i.value()
}