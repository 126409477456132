/**
 * Created by Lily on 2019/7/10.
 */

import RestResource from "rest-resource";

const api = '/salesorg';
//查询销售记录
export function fetchQuerySale(queryOption,page) {
    return RestResource.client
        .get(`${api}/sales`)
        .with_param({ page: page, size: 10,...queryOption})
        .execute();
}
//查询佣金记录
export function fetchQueryCommission(queryOption,page) : Promise<any> {
    return RestResource.client
        .get(`${api}/commission_transaction`)
        .with_param({ page: page, size: 10,...queryOption})
        .execute()
}
//查询产品分佣策略记录
export function fetchQueryProduct(queryOption,page) : Promise<any> {
    const { channelId, ...other } = queryOption;
    return RestResource.client
        .get(`${api}/channels/${channelId}/products`)
        .with_param({ page: page, size: 10, ...other})
        .execute()
}
//查询产品已授权组织(不分页)
export function fetchQueryProductOrg(queryOption){
    return RestResource.client
        .get(`${api}/org_prod_allocation/all`)
        .with_param( queryOption )
        .execute()
}
//产品授权组织
export function fetchProductAuthorizeOrg(params){
    return RestResource.client
        .post(`${api}/org_prod_allocation/batch/authorize`)
        .with_param(params)
        .should_raise_server_error()
        .execute()
}
//移除产品已授权组织
export function fetchDeleteProductOrg(id){
    return RestResource.client
        .delete(`${api}/org_prod_allocation/${id}`)
        .should_raise_server_error()
        .execute()
}
//查询渠道下组织
export function fetchQueryOrganization(queryOption,page) : Promise<any> {
    const { channelId, ...other } = queryOption
    return RestResource.client
        .get(`${api}/channels/${channelId}/organizations`)
        .with_param({ page: page, size: 10, ...other })
        .execute()
}
//查询渠道下组织（不分页）
export function fetchQueryOrganizationAll( channelId ){
    return RestResource.client
        .get(`${api}/channels/${channelId}/organizations/list`)
        .execute()
}
//设置策略
export function fetchSetPolicy(id,params) : Promise<any> {
    return RestResource.client
        .put(`${api}/org_prod_allocation/${id}/policy`)
        .with_param(params)
        .execute()
}
//策略信息
export function fetchGetPolicyById(id) : Promise<any> {
    return RestResource.client
        .get(`${api}/org_prod_allocation/${id}/policy`)
        .execute()
}
