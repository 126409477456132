/**
 * Created by Lily on 2019/7/3.
 */
import React, { Component, Fragment } from 'react';
import { ThunkDispatch } from "redux-thunk";
import { Link,RouteComponentProps as BasicRouteProps,  } from 'react-router-dom';
import { fetchQueryProductOrg, fetchDeleteProductOrg, fetchProductAuthorizeOrg } from '../service';
import QueryResult from './../../components/QueryResult';
import { requery, deleteRecord } from './../../components/QueryResult/actions';
import { Button, Divider, message } from 'antd';
import { CommissionPolicyType } from './../constants';
import { connect } from 'react-redux';
import OrgMadal from './orgModal';
import Immutable from 'immutable';

const MyTable = QueryResult.ResultTable();

const AuthorizedOrgTable = QueryResult.withQueryResultView(fetchQueryProductOrg, 'list.authorizedOrg')(MyTable);


interface IProps extends BasicRouteProps{
    channelId:string;
    list: any[];
    deleteRecord:(id)=>void
    queryRecord:(page, queryOption)=>void
}

interface IState {
    queryOption: {
        channelId:string;
        productId:string;
    };
    visible:boolean;
}

class ProductAuthorizedOrg extends Component<IProps,IState>{

    state: IState = {
        queryOption: {
            channelId: this.props.channelId,
            productId: this.props.location.state.product.id
        },
        visible: false,
    };
    commissionQuota = this.props.location.state.commissionQuota

    showModal = () => {
        this.setState({ visible: true });
    };
    hideModal = () => {
        this.setState({ visible: false });
    };

    handleOk = (keys) => {
        let params = {
            channelId:this.props.channelId,
            productId:this.props.location.state.product.id,
            productName:this.props.location.state.product.name,
            orgIds:keys,
        };
        fetchProductAuthorizeOrg(params).then(res=>{
            message.success('添加成功！')
            this.props.queryRecord(0,this.state.queryOption)
            this.hideModal()
        }).catch(err=>{
            message.warning('添加失败！')
        })

    };

    handleDelete = (id)=>{
        fetchDeleteProductOrg(id).then(res=>{
            message.success('移除成功！')
            this.props.deleteRecord(id)
        }).catch(err=>{
            message.warning('移除失败！')
        })
    }

    columns = [
        {
            title: '组织名称',
            dataIndex: 'organization.name'
        }, {
            title: '佣金策略',
            dataIndex: 'commissionPolicyType',
            render:(text,record)=>(CommissionPolicyType[text])
        }, {
            title: '操作',
            render: (text,record) => (
                <Fragment>
                    <Link to={{
                        pathname:`/channel/product/policy/${record.id}`,
                        state : { commissionQuota: this.commissionQuota }
                        }}>设置佣金策略</Link>
                    <Divider type="vertical" />
                    <a onClick={ ()=>this.handleDelete(record.id) }>移除</a>
                </Fragment>
            )
        }
    ]

    getSelectIds = (list)=>{
        return list ? list.map(item=>item.getIn(['organization','id'])).toJS() :[];
    }

    render() {
        const { location:{ state }, channelId, list } = this.props;

        return (
            <div>
                <div className="flex-row-between" style={{marginBottom:20}}>
                    <span>{ state.product.name }</span>
                    <Button type="primary" onClick={this.showModal}>添加组织</Button>
                    <OrgMadal
                        selected = { this.getSelectIds(list) }
                        channelId = { channelId }
                        visible={this.state.visible}
                        onCancel={this.hideModal}
                        onOk={this.handleOk}
                    />
                </div>

                <AuthorizedOrgTable
                    queryOption = { this.state.queryOption }
                    resultProps={ {
                        columns: this.columns,
                        scroll: { x: 1000 },
                        size:"middle"
                    } }
                    noPagination={true}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { user, queryResult } = state;
    return {
        channelId: user.user.channelId,
        list : queryResult.getIn(['list.authorizedOrg','content'])
    }
}
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        queryRecord: (page,queryOption)=>dispatch(requery('list.authorizedOrg', page, fetchQueryProductOrg, queryOption)),
        deleteRecord: (recordId)=>dispatch(deleteRecord('list.authorizedOrg', recordId))
    };
}
export default connect(mapStateToProps,mapDispatchToProps)(ProductAuthorizedOrg);