import React,{ Component,Fragment } from 'react';
import {
    Button,
    Form,
    Input,
    Icon,
    InputNumber,
} from 'antd';
import CommissionRates from './CommissionRates';
import {FormComponentProps} from 'antd/es/form';
import './rangedRates.less';
const FormItem = Form.Item;

interface IProps extends FormComponentProps{
    title: string;
    data:any;
    precision:number;
}

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
        md: {span: 16},
    },
};
const innerFormItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
};
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};

let id = 1;

class RangedRates extends Component<IProps>{

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        form.setFieldsValue({
            keys: nextKeys,
        });
    };

    remove = k => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    renderRemoveIcon(k,i){
        return i > 0 ? (
                <Icon
                    className="delete-button"
                    type="minus-circle-o"
                    onClick={() => this.remove(k)}
                />
            ) : null
    }

    createArray=(count)=>{
        let arr:number[] = [];
        for( let i = 0;i<count;i++){
            arr.push(i)
        }
        return arr;
    }
    render (){
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { title, data, precision } = this.props;

        let rangedRates = data ? data.rangedRates :[0];
        let settleDay = data ? data.settleDay :null;

        let init = this.createArray(rangedRates.length);

        getFieldDecorator('keys', { initialValue: init });
        const keys = getFieldValue('keys');
        const forms = keys.map((k, i) => {
            return (
                <div key={k}>
                    <FormItem label={`${title}区间`} {...formItemLayout}>
                        <div className="flex-row" >
                            <FormItem {...innerFormItemLayout}>
                                {getFieldDecorator(`lowbound[${k}]`,{
                                    rules: [{ required: true, message: '请输入区间下限' }],
                                    initialValue: rangedRates[k]?rangedRates[k].lowbound:null
                                })(
                                    <InputNumber style={{ width: 100}} min={0} precision={precision}/>
                                )}
                            </FormItem>
                            <span style={{ marginRight:10,marginLeft:10}}>-</span>
                            <FormItem {...innerFormItemLayout}>
                                {getFieldDecorator(`upbound[${k}]`,{
                                    rules: [{ required: k!==keys.length-1, message: '请输入区间上限' }],
                                    initialValue: rangedRates[k]?rangedRates[k].upbound: null
                                })(
                                    <InputNumber style={{ width: 100}} min={0} precision={precision}/>
                                )}
                            </FormItem>
                            { this.renderRemoveIcon( k,i ) }
                        </div>
                    </FormItem>
                    <CommissionRates form={this.props.form} k={ k }
                                     data={ rangedRates[k]?rangedRates[k].commissionRates:null }/>
                </div>
            )
        })


        return (
            <Fragment>
                <FormItem label = "每月结算日" {...formItemLayout}
                          help="请填写1~28之间的日期">
                    {getFieldDecorator('settleDay',{
                        rules: [{ required: true, message: '请输入每月结算日' }],
                        initialValue: settleDay
                    })(
                        <InputNumber min={1} max={28} style={{ width: 100, marginRight: 15 }} />
                    )}
                </FormItem>
                { forms }
                <FormItem {...formItemLayoutWithOutLabel}>
                    <Button type="dashed" onClick={ this.add } style={{ width: 200 }}>
                        <Icon type="plus" /> 添加销售区间
                    </Button>
                </FormItem>
            </Fragment>
        )
    }

}
export default RangedRates
