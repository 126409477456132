
export const AUTHENTICATED = "AUTHENTICATED";
export type AUTHENTICATED = typeof AUTHENTICATED;

export const SAVE_USER = "SAVE_USER";
export type SAVE_USER = typeof SAVE_USER;

export const CLEAR_USER = "CLEAR_USER";
export type CLEAR_USER = typeof CLEAR_USER;

export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";
export type AUTHENTICATE_FAILED = typeof AUTHENTICATE_FAILED;

export type ACTION_NAME =
  //  | AUTHENTICATE
  AUTHENTICATED | SAVE_USER | CLEAR_USER | AUTHENTICATE_FAILED;
