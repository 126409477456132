
import { doReducer } from './../reducer_helper';
import { AnyAction } from "redux";
import { GlobalState } from "./type";
import { GlobalAction } from "./action";
import {
  CHANGE_COLLAPSED,
  CHANGE_MOBILE,
} from "./constant";

//store中初始状态的接口声明
const initState: GlobalState = {
  collapsed: false,
  isMobile:true
};

export const REDUCER_TABLE = {};

REDUCER_TABLE[CHANGE_COLLAPSED] = (state, data) => {
  return {
    ...state,
    collapsed:data
  };
};

REDUCER_TABLE[CHANGE_MOBILE] = (state, data) => {
  return {
    ...state,
    isMobile:data
  };
};

export default function reducers( state : GlobalState = initState , action: GlobalAction | AnyAction) {
  return doReducer(state, action, REDUCER_TABLE);
}


