/**
 * Created by Lily on 2019/7/3.
 */

import React, {Component, Fragment} from 'react';
import {CommissionPolicyType} from './../constants';
import {
    Form,
    Radio,
    message,
    Spin
} from 'antd';
import {Dispatch} from 'redux';
import {FormComponentProps} from 'antd/es/form';
import SingleTradeAmountForm from './component/SingleTradeAmountForm';
import MonthlySalesAmountForm from './component/MonthlySalesAmountForm';
import MonthlySalesQuantityForm from './component/MonthlySalesQuantityForm';
import { fetchSetPolicy,fetchGetPolicyById } from './../service';
import * as H from 'history';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
        md: {span: 20},
    },
};
type RoutingType = { location: H.Location };
interface IProps extends FormComponentProps {
    dispatch: Dispatch<any>;
    history:any;
    match: {
        url: string;
        path: string;
        params: any ;
    };
    location:H.Location;
}

class ProductOrgPolicy extends Component<IProps> {

    state = {
        commissionPolicyType:'SINGLE_TRADE_AMOUNT',
        data:{
            commissionPolicy:null,
            commissionPolicyType : null
        },
        loading:true
    };
    singleTradeAmountRef = null;
    monthlySalesAmountRef = null;
    monthlySalesQuantityRef = null;
    recordId = this.props.match.params.id;
    commissionQuota = this.props.location.state.commissionQuota;



    componentDidMount(){
        console.log('-----commissionQuota:',this.commissionQuota)
        this.query(this.recordId)
    }
    query(id){
        fetchGetPolicyById(id).then(res=>{
            this.setState({
                loading:false,
                data: res.data,
                commissionPolicyType : res.data.commissionPolicyType||'SINGLE_TRADE_AMOUNT'
            })
        })
    }
    handleCancel = () =>{
        this.props.history.goBack()
    }

    handleSubmit = (content) => {
        const { commissionPolicyType } = this.state;
        let params = {
            commissionPolicyType:commissionPolicyType,
            policyContent: JSON.stringify(content)
        };
        fetchSetPolicy(this.recordId,params).then(res=>{
            message.success('设置成功！')
            this.props.history.goBack()
        })
    };

    renderContent(commissionPolicyType) {
        const { data } = this.state;
        switch (commissionPolicyType) {
            case 'SINGLE_TRADE_AMOUNT':
                return <SingleTradeAmountForm
                    wrappedComponentRef={ (ref)=>this.singleTradeAmountRef = ref}
                    onSubmit = { this.handleSubmit }
                    onCancel = { this.handleCancel }
                    commissionQuota = { this.commissionQuota }
                    data = { data.commissionPolicy }
                />;
            case 'MONTHLY_SALES_AMOUNT':
                return <MonthlySalesAmountForm
                    wrappedComponentRef={ (ref)=>this.monthlySalesAmountRef = ref}
                    onSubmit = { this.handleSubmit }
                    onCancel = { this.handleCancel }
                    commissionQuota = { this.commissionQuota }
                    data = { data && data.commissionPolicyType === 'MONTHLY_SALES_AMOUNT' && data.commissionPolicy }
                />;
            case 'MONTHLY_SALES_QUANTITY':
                return <MonthlySalesQuantityForm
                    wrappedComponentRef={ (ref)=>this.monthlySalesQuantityRef = ref}
                    onSubmit = { this.handleSubmit }
                    onCancel = { this.handleCancel }
                    commissionQuota = { this.commissionQuota }
                    data = { data && data.commissionPolicyType === 'MONTHLY_SALES_QUANTITY' && data.commissionPolicy }
                />;
        }
    }

    renderRadioItems(){
        let items:any = [];
        for(let i in CommissionPolicyType ){
            items.push(
                <Radio key = {i} value={i}>
                    { CommissionPolicyType[i] }
                </Radio>
            )
        }
        return items;
    }

    render() {

        const {commissionPolicyType,data,loading} = this.state;

        if(loading){
            return <Spin/>;
        }
        return (
            <div >
                <FormItem {...formItemLayout} label={ this.commissionQuota.type === 'REVENUE_RATE'? "总分佣比(%)" : "总分佣金额(￥)"}
                    >
                    <span className="ant-form-text">{ this.commissionQuota.quota }</span>

                    <span className="ant-form-text" style={{color: '#999',fontSize: 12, marginLeft:20}}>注：设置各级分佣值不应超过总分佣值 </span>

                </FormItem>
                <FormItem {...formItemLayout} label="佣金策略">
                    <Radio.Group onChange={(e)=>{
                            this.setState({
                                commissionPolicyType: e.target.value,
                            });
                        }} value={ commissionPolicyType }>

                        { this.renderRadioItems() }

                    </Radio.Group>
                </FormItem>
                {
                    this.renderContent(commissionPolicyType)
                }
            </div>
        )
    }
}

export default Form.create<IProps>()(ProductOrgPolicy);
