import React,{ Component,Fragment } from 'react';
import {
    Form,
    Input,
    InputNumber,
} from 'antd';
import {FormComponentProps} from 'antd/es/form';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
        md: {span: 20},
    },
};
const innerFormItemLayout = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
};

interface IProps extends FormComponentProps{
    k?:number;
    data: any;
}

class CommissionRates extends Component<IProps>{

    render (){
        const { getFieldDecorator } = this.props.form;
        const { k = 0, data } = this.props;
        return (
            <Fragment>
                <FormItem {...formItemLayout} label="分佣比例(%)">
                    <div className="flex-row" >
                        <FormItem label = "渠道" colon={false} {...innerFormItemLayout}>
                            {getFieldDecorator(`CHANNEL[${k}]`,{
                                rules: [{ required: true, message: '请输入渠道占比' }],
                                initialValue: data && data.CHANNEL
                            })(
                                <InputNumber style={{ width: 100, marginRight: 15 }} min={0}/>
                            )}
                        </FormItem>
                        <FormItem label = "组织" colon={false} {...innerFormItemLayout}>
                            {getFieldDecorator(`ORGANIZATION[${k}]`,{
                                rules: [{ required: true, message: '请输入组织占比' }],
                                initialValue: data && data.ORGANIZATION
                            })(
                                <InputNumber style={{ width: 100, marginRight: 15 }} min={0}/>
                            )}
                        </FormItem>
                        <FormItem label = "销售" colon={false} {...innerFormItemLayout}>
                            {getFieldDecorator(`SALES[${k}]`,{
                                rules: [{ required: true, message: '请输入销售占比' }],
                                initialValue: data && data.SALES
                            })(
                                <InputNumber style={{ width: 100, marginRight: 15 }} min={0}/>
                            )}
                        </FormItem>
                        <FormItem label = "邀请人" colon={false} {...innerFormItemLayout}>
                            {getFieldDecorator(`INVITER[${k}]`,{
                                rules: [{ required: true, message: '请输入邀请人占比' }],
                                initialValue: data && data.INVITER
                            })(
                                <InputNumber style={{ width: 100, marginRight: 15 }} min={0}/>
                            )}
                        </FormItem>
                    </div>
                </FormItem>


            </Fragment>
        )
    }

}
export default CommissionRates