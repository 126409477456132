import React from 'react';
import {Drawer, Icon, Dropdown} from 'antd';
import SiderMenu from './SiderMenu';
import BaseMenu from './BaseMenu';

// 创建类型接口
interface IProps {
    logo?: React.ReactNode,
    mode?: string,
    menuData: any,
    isMobile: boolean,
    collapsed: boolean,
    onCollapse: (collapsed: boolean) => void
    pathname?: any
}
const SiderMenuWrapper = React.memo<IProps>(props => {
    const {isMobile, menuData, collapsed, onCollapse} = props;
    return isMobile ? (
            <Drawer
                visible={!collapsed}
                placement="left"
                closable={false}
                onClose={() => onCollapse(true)}
                bodyStyle={{
        padding: 0,
        height: '100vh',
      }}
            >
                <SiderMenu {...props} menuData={menuData} collapsed={isMobile ? false : collapsed}/>
            </Drawer>
        ) : (
            <SiderMenu {...props} menuData={menuData}/>
        );
});

const TopMenuWrapper = React.memo<IProps>(props => {
    const {isMobile, menuData, collapsed, onCollapse, pathname} = props;
    return isMobile ? (
            <Dropdown overlay={<BaseMenu
                theme='dark'
                pathname={ pathname }
                menuData={ menuData }
            />} trigger={['click']}>
                <a className="ant-dropdown-link" href="#">
                    <div style={{ paddingRight:10, paddingLeft:10 }}
                    >
                        <Icon type="ellipsis" />
                    </div>
                </a>
            </Dropdown>
        ) : (
            <BaseMenu
                theme='dark'
                mode='horizontal'
                pathname={ pathname }
                menuData={ menuData }
            />
        );
});

export default {
    SiderMenuWrapper,
    TopMenuWrapper
};
