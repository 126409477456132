/**
 * Created by Lily on 2019/7/10.
 */

import RestResource from "rest-resource";

const api = '/salesorg';
//佣金收入月报表
export function fetchReportsByMonth() {
    return RestResource.client
        .get(`${api}/commissions/reports/byMonth`)
        .execute();
}
//收入排行
export function fetchReportsByOrder(partyId,role) {
    return RestResource.client
        .get(`${api}/commissions/reports/subpartiesOf/${partyId}?role=${role}&start=2019-08-01`)
        .execute();
}