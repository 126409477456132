import React, {Component} from 'react';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
} from 'bizcharts';

interface IProps {
    height:number;
    tooltipFormat:any;
    labelFormat:any;
    padding?:[number, number, number, number];
    data?: Array<{
        item: number | string;
        value: number;
    }>;
}
interface IState {

}
class Line extends Component<IProps,IState> {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    componentDidUpdate(preProps) {
        const {data} = this.props;
        if (data !== preProps.data) {

        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            data,
            height,
            tooltipFormat,
            padding='auto',
            labelFormat
        } = this.props;

        return (
            <div>
                <div>
                    <Chart height={height} data={data} padding={padding}  forceFit>
                        <Axis name="item" label={labelFormat}/>
                        <Axis name="value" />
                        <Tooltip crosshairs={{ type: "y"}} />

                        <Geom type="line"
                              position="item*value"
                              color={'#fd6a37'}
                              tooltip={ ["item*value",tooltipFormat ] } size={2} />
                        <Geom
                            type="point"
                            position="item*value"
                            tooltip={ ["item*value",tooltipFormat ] }
                            size={4}
                            shape={"circle"}
                            color={'#fd6a37'}
                            style={{ stroke: "#fff", lineWidth: 1 }}
                        />
                    </Chart>
                </div>
            </div>
        );
    }
}

export default Line;
