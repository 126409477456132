/**
 * Created by Lily on 2019/7/3.
 */

import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';
import {fetchQueryProduct} from '../service';
import QueryResult from './../../components/QueryResult';
import {Input} from 'antd';
import { CommissionQuotaType } from './../constants';
import { yuan, rate } from './../../utils/utils';
import Immutable from 'immutable';

const {Search} = Input;

interface IProps {
    channelId:string;
}

interface IState {
    queryOption: {[key: string]: string};
}

const MyTable = QueryResult.ResultTable();
const MyTableQueryResult = QueryResult.withQueryResultView(fetchQueryProduct, 'list.product')(MyTable);

class ProductTable extends Component<IProps,IState> {

    state: IState = {
        queryOption: {
            keyword: '',
            channelId: this.props.channelId
        }
    };

    handleSearch = (value) => {

        let data = Object.assign({}, this.state.queryOption, {keyword: value})
        this.setState({
            queryOption: data
        })

    };
    columns = [
        {
            title: '产品名称',
            dataIndex: 'product.name'
        }, {
            title: '佣金',
            dataIndex: 'commissionQuota',
            render: (text, record) => {
                return text.type === CommissionQuotaType.revenueRate ? rate(text.quota) : yuan(text.quota)
            }
        }, {
            title: '默认的分佣策略',
            dataIndex: 'defaultCommissionPolicy'
        }, {
            title: '操作',
            render: (text, record) => (
                <Fragment>
                    <Link to={ {
                        pathname :`/channel/product/authOrg`,
                        state : { product : record.product, commissionQuota: record.commissionQuota }
                    } }>授权组织</Link>
                </Fragment>
            )
        }
    ]

    render() {
        console.log('-this.state.queryOption --',this.state.queryOption )
        return (
            <div>
                <div style={{marginBottom:20}}>
                    <Search
                        placeholder="请输入关键字搜索"
                        onSearch={value => this.handleSearch(value)}
                        style={{ width: 200 }}
                    />
                </div>
                <MyTableQueryResult
                    queryOption={ this.state.queryOption }
                    resultProps={ {
                        columns: this.columns,
                        scroll: { x: 1000 },
                        size:"middle"
                    } }/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {user} = state;
    return {
        channelId: user && user.user && user.user.channelId
    }
}

export default connect(mapStateToProps, null)(ProductTable)