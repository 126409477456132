import React, {Component} from 'react';
import {Layout} from 'antd';
import {connect} from 'react-redux';
import { getChannelMenuConfig } from './../constants';
import {changeCollapsed} from '../reducers/global/action';
import MenuWarpper from './../components/MenuWrapper';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import RouteWithSubRoutes from './../RouteWithSubRoutes';

const { Sider,Content } = Layout;

const SiderMenuWrapper = MenuWarpper.SiderMenuWrapper;


const getMenu = ( currentUserRoles )=>{
    const menu = getChannelMenuConfig();

    return menu.map(item=>{
        let isAuth = false;
        for( let i of item.authority ){
            if(currentUserRoles.includes(i)){
                isAuth = true
            }
        }
        return isAuth ? item :null;
    }).filter(item=>!!item);
}

class SiderLayout extends Component {
    constructor(props) {
        super(props);
    }

    handleMenuCollapse = collapsed => {
        const { changeCollapsed } = this.props;
        changeCollapsed(collapsed);
    };

    render() {
        const { collapsed,isMobile,location ,routes,currentUserRoles} = this.props;
        return (
            <React.Fragment>
                <SiderMenuWrapper style={{ minHeight: '100vh' }}
                           collapsed={ collapsed }
                           onCollapse={ this.handleMenuCollapse }
                           menuData={ getMenu(currentUserRoles) }
                           isMobile={ isMobile }
                           pathname = { location.pathname }
                           mode = 'inline'
                           {...this.props}

                />
                <Content style={{padding: 24, background: '#ffffff'}}>
                    <Switch>
                        {
                            routes && routes.map((route, i) => {
                                return (
                                    <RouteWithSubRoutes key={i} {...route} />)
                            })
                        }
                    </Switch>

                </Content>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {global, user} = state;

    const orgName = user && user.user ? user.user.orgName : "";
    const currentUserRoles = user && user.user ?user.user.roles:[] ;
    return {
        collapsed: global.collapsed,
        isMobile: global.isMobile,
        orgName: orgName,
        currentUserRoles:currentUserRoles
    };
};
function bindActions(dispatch) {
    return {
        changeCollapsed: (collapsed) => dispatch(changeCollapsed(collapsed))
    };
}

export default connect(
    mapStateToProps,
    bindActions
)(SiderLayout);
