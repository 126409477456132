import { useState, useEffect } from 'react'

/**
 * 倒计时
 * @param initialData 初始时间
 */
export function useCountDown(
  initialData: number = 0
): [number, (count: number) => void] {
  const [countTime, setCountTime] = useState(initialData)
  let timer
  useEffect(() => {
    if (countTime > 0) {
      timer = setTimeout(() => setCountTime(countTime - 1), 1000)
    }
    return () => timer && clearTimeout(timer)
  }, [countTime])
  return [
    countTime,
    (count: number) => {
      timer && clearTimeout(timer)
      setCountTime(count)
    }
  ]
}
