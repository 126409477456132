

/**
 * Created by Lily on 2019/7/3.
 */

import React, {Component, Fragment} from 'react';
import {
    Form,Button,message
} from 'antd';
import {FormComponentProps} from 'antd/es/form';
import RangedRates from './RangedRates';
import { sum } from './../../../utils/utils';

const submitFormLayout = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 16, offset: 4},
    },
};
const FormItem = Form.Item;

interface IProps extends FormComponentProps {
    onCancel:()=>void;
    onSubmit:(params)=>void;
    commissionQuota:{quota:number,
        type:string};
    data:any;
}

class MonthlySalesQuantity extends Component<IProps> {

    handleSubmit = (e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { keys = [0], lowbound, upbound,CHANNEL, ORGANIZATION,SALES,INVITER, settleDay } = values;
                let list:any = [];
                let valid = true;
                const { commissionQuota: {
                    quota,
                    type
                } } = this.props;
                for (let i = 0; i < keys.length; i++) {
                    if( i!=keys.length && lowbound[i] >= upbound[i] ){
                        message.warning(`第${i+1}个区间，上限应大于下限！`);
                        valid = false;
                        break;
                    }
                    if( i>0 && lowbound[i] < upbound[i-1] ){
                        message.warning(`第${i+1}个区间，下限应大于等于前一个区间的上限！`);
                        valid = false;
                        break;
                    }
                    let s:number = sum([CHANNEL[i],ORGANIZATION[i],SALES[i],INVITER[i]]);
                    if( s > quota){
                        message.warning('分佣比例和不应超过产品总分佣比');
                        valid = false;
                        break;
                    }
                    list.push(
                        {
                            lowbound: lowbound[i],
                            upbound: upbound[i],
                            commissionRates: {
                                CHANNEL: CHANNEL[i],
                                ORGANIZATION: ORGANIZATION[i],
                                SALES: SALES[i],
                                INVITER: INVITER[i]
                            }
                        }
                    )
                }

                if( valid ) {
                    let content = {
                        rangedRates: list ,
                        settleDay: settleDay
                    };
                    this.props.onSubmit(content)
                }
            }
        })
    }

    render() {
        const { data } = this.props;
        return (
            <Form >
                <RangedRates form = { this.props.form } key="MONTHLY_SALES_QUANTITY" title = "销售量"
                             data = { data } precision = {0}/>

                <FormItem {...submitFormLayout} style={{ marginTop: 32 }}>
                    <Button type="primary" onClick={ this.handleSubmit }>
                        保存
                    </Button>
                    <Button style={{ marginLeft: 8 }} onClick={ this.props.onCancel }>
                        取消
                    </Button>
                </FormItem>

            </Form>
        )
    }
}

export default Form.create<IProps>()(MonthlySalesQuantity);
