import  React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {getConfig, getMenuConfig} from './constants';
import ConfigContext  from './ConfigContext';
import {routes} from './RouterConfing';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import {connect} from "react-redux";
import {AuthStatus} from "./user/type";
import {loadCurrentUser} from "./user/action";

class App extends React.Component {
    componentWillMount() {
        const {sessionStatus, loadCurrentUser} = this.props;

        if (sessionStatus == AuthStatus.UNKNOWN) {
            console.log("try to load current user");
            loadCurrentUser();
        }
    }

    getContext = (app_mode) => {
        let config     = getConfig(app_mode);
        config['menu'] = getMenuConfig(app_mode)
        return config
    }

    render() {
        return (
            <ConfigContext.Provider value={ this.getContext(process.env.REACT_APP_MODE) }>
                { routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />) }
            </ConfigContext.Provider>
        );
    }
}
function mapStateToProps(state) {
    const user = state.user;
    return {
        sessionStatus: user.authenticated,
    };
}

function bindActions(dispatch) {
    return {
        loadCurrentUser: () => dispatch(loadCurrentUser())
    };
}
export default connect(
    mapStateToProps,
    bindActions
)(App);
