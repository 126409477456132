import React, { PureComponent, Suspense } from 'react';
import { Layout } from 'antd';
import './index.less';
import PageLoading from '../PageLoading';


const BaseMenu = React.lazy(() => import('./BaseMenu'));
const { Sider } = Layout;

interface IProps {
  logo?:any,
  collapsed:boolean,
  onCollapse?:(collapsed:boolean)=>void,
  menuData:any,
  theme?:"light" | "dark" | undefined,
  pathname?:any
}

export default class SiderMenu extends PureComponent<IProps,any> {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: '/',
    };
  }

  handleOpenChange = openKeys => {
    this.setState({
      openKeys: [...openKeys],
    });
  };

  render() {
    const { logo, collapsed, onCollapse,menuData } = this.props;
    const { openKeys } = this.state;
    const defaultProps = collapsed ? {} : { openKeys };
    return (
      <Sider
        trigger={null}
        breakpoint="lg"
        className='siderMenu'
        width={180}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        { logo }
        <Suspense fallback={<PageLoading />}>
          <BaseMenu
              handleOpenChange={ this.handleOpenChange }
              style={{ padding: '16px 0', width: '100%' }}
              menuData={menuData}
              {...this.props}
              {...defaultProps}/>
        </Suspense>
      </Sider>
    );
  }
}
