/**
 * Created by Lily on 2019/7/11.
 */

import { createContext } from 'react';

export interface ConfigContextProps {
    goodArticleLink?: string,
    bbtLink?:string,
    menu?: any
}

const ConfigContext: React.Context<ConfigContextProps> = createContext({});

export default ConfigContext;
