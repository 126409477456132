

/**
 * Created by Lily on 2019/7/3.
 */

import React, {Component, Fragment} from 'react';
import {
    Form, Button,message
} from 'antd';
import {FormComponentProps} from 'antd/es/form';
import CommissionRates from './CommissionRates';
import { sum } from './../../../utils/utils';
const FormItem = Form.Item;

const submitFormLayout = {
    wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 16, offset: 4},
    },
};

interface IProps extends FormComponentProps {
    onCancel:()=>void;
    onSubmit:(params)=>void;
    commissionQuota:{quota:number,
        type:string};
    data:any;
}

class SingleTradeAmount extends Component<IProps> {

    handleSubmit = (e)=>{
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { CHANNEL, ORGANIZATION,SALES,INVITER } = values;
                const { commissionQuota: {
                    quota,
                    type
                } } = this.props;
                let s:number = sum([CHANNEL[0],ORGANIZATION[0],SALES[0],INVITER[0]]);
                if( s > quota){
                    return message.warning('分佣比例和不应超过产品总分佣比')
                }
                let content = {
                    commissionRates:{
                        CHANNEL:CHANNEL[0],
                        ORGANIZATION:ORGANIZATION[0],
                        SALES:SALES[0],
                        INVITER:INVITER[0]
                    }
                };
                this.props.onSubmit( content )
            }
        })
    }

    render() {
        const { data } = this.props;
        return (
            <Form >
                <CommissionRates form = { this.props.form } key="SINGLE_TRADE_AMOUNT"
                    data = { data && data.commissionRates }
                />
                <FormItem {...submitFormLayout} style={{ marginTop: 32 }}>
                    <Button type="primary" onClick={ this.handleSubmit }>
                        保存
                    </Button>
                    <Button style={{ marginLeft: 8 }} onClick={ this.props.onCancel }>
                        取消
                    </Button>
                </FormItem>
            </Form>
        )
    }
}

export default Form.create<IProps>()(SingleTradeAmount);
